












import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
@Observer
@Component({
  components: {
    'community-owner-board': () => import('@/modules/common/components/community-owner-board.vue'),
    'my-community-board': () => import('@/modules/common/components/my-community-board.vue'),
  },
})
export default class CommunityOwnerSection extends Vue {
  createPostController = createPostController
  @Prop({ default: '200px' }) topPosition
  @Prop({ default: false }) hideUserInfo
  get cssVars() {
    return {
      '--position-top': this.topPosition,
    }
  }
}
